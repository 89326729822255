@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Work Sans", sans-serif;
}

@layer components {

  .text-para {
    @apply text-[12px] leading-4 font-normal md:font-medium md:text-[17px] md:leading-[26px] text-[#6B6B6B];
  }
}
